import * as React from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Form from 'react-bootstrap/Form';
import Button from '@mui/material/Button';
import InputGroup from 'react-bootstrap/InputGroup';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import axiosInstance from '../../redux/axios';

export default function ChangePassword(){
    const [oldPass, setOldPass] = React.useState('');
    const [pass, setPass] = React.useState('');
    const [confPass, setConfPass] = React.useState('');
    const [showPassC, setShowC] = React.useState(false);
    const [showPass, setShow] = React.useState(false);
    const [showPass1, setShow1] = React.useState(false);

    const [error, setError] = React.useState('');
    const [success, setSucess] = React.useState('');

    React.useEffect(() => {
        setError('');
        setSucess('');
    }, [pass, oldPass,confPass])

    const validate = () => {
        return oldPass !== "" && pass !== "" && confPass !== ""
    }

    const changePass = async (e) => {
        e.preventDefault();
        if(validate()){
            if(pass === confPass){
                try{
                    let data = {
                        oldPassword: oldPass,
                        newPassword: confPass
                    }
                    const response = await axiosInstance.post('users/pass-update', data);
                  
                    if(response.data === 'OK'){
                        setSucess('Password changed successfully');
                    }
                }catch(err){
                    if(err?.response?.data){
                        setError(err?.response?.data)
                    }
                }
            }else{
                setError("New password doesn't match");
            }
        }else{
            setError("Fill in the form.")
        }
    }
    return(
        <div className="dashboard_page">
            <Container maxWidth="md">
                <Paper className="paper_card pt-3 pb-3 ps-3 pe-3" variant="elevation" elevation={6}>
                        <Form className="pt-4 pb-3">
                        <Form.Group className="mb-3" controlId="changeForm.Current">
                                <Form.Label>Current Password<span>*</span></Form.Label>
                                <InputGroup hasValidation>
                                    <Form.Control value={oldPass} onChange={e => setOldPass(e.target.value)} type={showPassC ? "text" : "password"} placeholder="" />
                                    <InputGroup.Text onClick={() => {setShowC(!showPassC)}} style={{cursor: 'pointer'}}>{showPassC ? <VisibilityIcon/> : <VisibilityOffIcon/>}</InputGroup.Text>
                                </InputGroup>
                            
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="changeForm.Password">
                                <Form.Label>Password<span>*</span></Form.Label>
                                <InputGroup hasValidation>
                                    <Form.Control value={pass} onChange={e => setPass(e.target.value)}  type={showPass ? "text" : "password"} placeholder="" />
                                    <InputGroup.Text onClick={() => {setShow(!showPass)}} style={{cursor: 'pointer'}}>{showPass ? <VisibilityIcon/> : <VisibilityOffIcon/>}</InputGroup.Text>
                                </InputGroup>
                            
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="changeForm.confirmPassword">
                                <Form.Label>Confirm Password<span>*</span></Form.Label>
                                <InputGroup hasValidation>
                                    <Form.Control value={confPass} onChange={e => setConfPass(e.target.value)}  type={showPass1 ? "text" : "password"} placeholder="" />
                                    <InputGroup.Text onClick={() => {setShow1(!showPass1)}} style={{cursor: 'pointer'}}>{showPass1 ? <VisibilityIcon/> : <VisibilityOffIcon/>}</InputGroup.Text>
                                </InputGroup>
                            
                            </Form.Group>

                            <div className="display_start">
                                <Button onClick={(e) => changePass(e)} type="submit" className="btn_about full-width">Submit</Button>
                            </div>
                            {error && <p className="text_error">{error}</p>}
                            {success && <p className="text_success">{success}</p>}
                        </Form>
                </Paper>
            </Container>
        </div>
    )
}