import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import CropFreeIcon from '@mui/icons-material/CropFree';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useNavigate } from 'react-router-dom';

import tokens from '../assets/img/tokens.png';

export default function AboutComponent(){    
    const navigate = useNavigate();

    return(
        <div className="about_component">
            <Container>
                <Grid container>
                    <Grid item xs={12} sm={8} md={8}>
                    <p>About Us</p>
                    <h1>Made for mass adoption</h1>
                    <p className="p_about text-grayed pt-2">
                    Cloud mining allows clients to participate in cryptocurrency mining without the need to manage physical hardware. By leveraging remote data centers with shared processing power, we offer great mining experience that is both cost-effective and efficient. Our state-of-the-art facilities and expert team ensure optimal performance, high security, and continuous operation, providing our clients with a hassle-free way to mine cryptocurrencies and achieve their financial goals.
                    <br/>
                    Join HashCash Mining Dubai and unlock the full potential of cloud mining. With our innovative solutions and dedicated support, success is always within reach.
                    </p>
                    </Grid>
                    <Grid item xs={4}></Grid>
                </Grid>
                <Grid container className="pt-4">
                    <Grid item xs={12} sm={8} md={8}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={6}>
                                <div className="grid_in_ab" >
                                    <div className="sp_in_ab"/>
                                    <p className="title_about"><CropFreeIcon/>Free trial</p>
                                    <p className="text-grayed">Claim XMR Miner X5 (standard) miner for a period of 4 days to experience the workflow and earnings for free. You just need to create an account and claim the miner in the dashboard.</p>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <div className="grid_in_ab">
                                    <div className="sp_in_ab"/>
                                    <p className="title_about"><GroupWorkIcon/>Team</p>
                                    <p className="text-grayed">Our team's expertise and strategic insight ensure every project's success. Join us to experience the future of mining, where innovation and dedication lead to unparalleled success.</p>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <div className="grid_in_ab">
                                    <div className="sp_in_ab"/>
                                    <p className="title_about"><LocalLibraryIcon/>Only 10% Fee</p>
                                    <p className="text-grayed">Maximize your earnings with HashCash Mining Dubai's exclusive upgrades. Choose a larger miner to unlock enhanced rewards, higher efficiency, and increased profitability. Elevate your cloud mining experience today!</p>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <div className="grid_in_ab">
                                    <div className="sp_in_ab"/>
                                    <p className="title_about"><CheckCircleOutlineIcon/>Support</p>
                                    <p className="text-grayed">Join our Telegram community to connect with our support team for instant assistance. Receive real-time help, updates, and personalized support, all at your fingertips. </p>
                                </div>
                            </Grid>
                        </Grid> 
                    </Grid>
                    <Grid className="position-relative" item xs={0} sm={4} md={4}>
                        <img className="img_t_about" src={tokens} alt='tpl' />
                    </Grid>
                </Grid>

                <Button className="btn_about" onClick={() => navigate('/register')}>Get Started</Button>
            </Container>
        </div>
    )
}