
import Divider from '@mui/material/Divider';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';

const plans = [
    {id: 0, init: 0, price: 132 , miner: 'XMR Miner X3', img: '/static/machines/x5.png', name: 'x0.5', pack: 'Common', color: 'rgb(9 93 57)', di: 5},
    {id: 1, init: 0, price: 282 , miner: 'XMR Miner X5', img: '/static/machines/x5.png', name: 'x1', pack: 'Standard', color: '#647D97', di: 10},
    {id: 2, init: 7, price: 564 , miner: 'HNS Miner HS3', img: '/static/machines/HS3.png' ,name: 'x2', pack: 'Gold', color: '#DC8707', di: 20},
    {id: 3, init: 14, price: 1690 ,miner: 'MICROBT WhatsMiner M50S++', img: '/static/machines/M50S.png', name: 'x6', pack: 'Platinum', color: '#0094FF', di: 34},
    {id: 4, init: 21, price: 2180 ,miner: 'Zcash Miner Z15 Pro', img: '/static/machines/Z15.png', name: 'x20', pack: 'Diamond', color: '#30D891', di: 50},
    {id: 5, init: 28, price: 2780 ,miner: 'Miner T21', img: '/static/machines/T21.png', name: 'x30', pack: 'VIP1',  color: '#F36EFF', di: 67},
    {id: 6, init: 35, price: 3150 ,miner: 'ETC Miner E9 Pro', img: '/static/machines/e9.png', name: 'x60', pack: 'VIP2',color: '#D4A70C', di: 100},
    {id: 7, init: 42, price: 6620 ,miner: 'Miner S21 Hyd.', img: '/static/machines/S21.png', name: 'x280', pack: 'VIP3', color: '#FF7C74', di: 200},
]

export default function MinerView({m}){

    const miner = m?.minerType
    const navigate = useNavigate();

    return(
        <div className="plan_item">
            <div className="plan_h1" style={{color: plans.find(x=> {return x.di === miner?.type})?.color}}>{plans.find(x=> {return x.di === miner?.type})?.miner}</div>
            <div className="plan_l">{plans.find(x=> {return x.di === miner?.type})?.pack}</div>
            
            <Divider/>

            <div className="plan_features" style={{color: 'black'}}>
                <div></div>
                <div><CheckCircleOutlineOutlinedIcon style={{color: plans.find(y=> {return y.di === miner?.type})?.color}}/>Hash Rate : {plans.find(x=> {return x.di === miner?.type})?.name}</div>
                
                <div><CheckCircleOutlineOutlinedIcon style={{color: plans.find(y=> {return y.di === miner?.type})?.color}}/>Daily income : {miner?.type} USDC</div>
                <div><CheckCircleOutlineOutlinedIcon style={{color: plans.find(y=> {return y.di === miner?.type})?.color}}/>Weekly income : {miner?.type * 7} USDC</div>
                <div><CheckCircleOutlineOutlinedIcon style={{color: plans.find(y=> {return y.di === miner?.type})?.color}}/>Monthly income : {miner?.type * 30} USDC</div>
                <div><CheckCircleOutlineOutlinedIcon style={{color: plans.find(y=> {return y.di === miner?.type})?.color}}/>Valid for : {m?.days_rent} days</div>
            </div>

            <Button style={{width: '100%'}} onClick={() => navigate('/user/miners')} className="btn_about mt-3">Details</Button>

        </div>
    )
}