import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    user: null,
    token: null
};

const authSlice = createSlice({
    name: "auth",
    initialState: initialState,
    reducers: {
        setAuth: (state, action) => {
            state.user = action.payload.user;
            state.token = action.payload.token;
        },
        updateUserMine: (state, action) => {
            state.user.balance = action.payload.balance;
            state.user.lastmined = action.payload.lastmined;
        },
        updateTrialClaimed: (state) => {
            if(state.user?.trial_claimed === 0){
                state.user.trial_claimed = 1;
            }
        },
        logOutAuth: (state) => {
            state.user = null;
            state.token = null;
        },
    }
});

export const {setAuthToken, setAuth, updateTrialClaimed, updateUserMine, logOutAuth} = authSlice.actions;

export default authSlice.reducer;