import "../assets/css/Map.css";
import Container from '@mui/material/Container';

const boxes=[
    {id: 0, name: 'Specialized DogeCoin & Kaspa mining equipment is connected at our data centers.', img: '/static/map/img_0.webp'},
    {id: 1, name: 'The purchase, placement, and maintenance of this equipment is a time-consuming process that only a select few professionals, like us, can manage.', img: '/static/map/img_1.webp'},
    {id: 2, name: "We've pioneered a straightforward method for mining DogeCoin & Kaspa that requires no effort on your part.", img: '/static/map/img_0.webp'},
    {id: 3, name: 'Rent one of our digital miners to gain access to a share of real mining power (TH/s) and energy efficiency (W/TH). Located in our data centers, these miners work tirelessly to mine DogeCoin & Kaspas for you daily. Our rental packages include a 10% service fee from earnings, already factored in.', img: '/static/map/img_3.webp'},
    {id: 4, name: ' The greater the power of your digital miner, the more DogeCoin & Kaspayou can mine efficiently.', img: '/static/map/img_0.webp'},
]

export default function DigitalMinersHome(){
    return(
        <>
            <h1 className="pb-5 text-centerM">How our digital miners work</h1>
            <Container>
                <div className="pos-r">
                    <div className="about-scroll-line pos-a d-lg-b d-n"></div>
                    <div>
                        {boxes.map(b =>
                            <div className={b.id%2===0 ? "about-box about-box_left" : "about-box about-box_right"} key={b.id}>
                                <div className="pb-2">{b.name}</div>
                                <div className="about-box__nmb about-box__nmb_1 bgc-purple-lighter">
                                    {b.id+1}
                                </div>
                                <div>
                                    <img style={{width: '100%'}} src={b.img} alt="imap" />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </Container>
        </>
    )
}
