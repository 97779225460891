import * as React from 'react';
import { useLocation , useNavigate } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import useMediaQuery from '@mui/material/useMediaQuery';

import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone';
//import WalletTwoToneIcon from '@mui/icons-material/WalletTwoTone';
import BadgeTwoToneIcon from '@mui/icons-material/BadgeTwoTone';
//import PaymentsTwoToneIcon from '@mui/icons-material/PaymentsTwoTone';
//import HelpTwoToneIcon from '@mui/icons-material/HelpTwoTone';
import ExitToAppTwoToneIcon from '@mui/icons-material/ExitToAppTwoTone';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import CircleTwoToneIcon from '@mui/icons-material/CircleTwoTone';
import CellWifiIcon from '@mui/icons-material/CellWifi';
import TelegramIcon from '@mui/icons-material/Telegram';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import axiosInstance from '../redux/axios';
import { logOutAuth } from '../redux/reducers/authReducer';
import { useDispatch} from 'react-redux';
import GroupAddIcon from '@mui/icons-material/GroupAdd';

import logo from '../assets/img/logo_small.png';

const drawerWidth = 240;
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open, matches }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(matches && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open, matches}) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  ...(!matches && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const settings = [
  {id: 0, name: 'Profile', route: '/user/profile'},
  {id: 1, name: 'Change Password', route: '/user/change-password'},
  {id: 2, name: 'Logout', route: '/user/none'}
];

const menuItems = [
    {id: 0, name: 'Dashboard', icon: DashboardTwoToneIcon, route: '/user/dashboard', subItems: null},
    /*{id: 1, name: 'Deposit', icon: WalletTwoToneIcon, route: '/user/deposit', subItems: [ {id: 0, name: 'Deposit Now', icon: CircleTwoToneIcon, route: '/user/deposit'}, {id: 1, name: 'Deposit History', icon: CircleTwoToneIcon, route: '/user/deposit/history'}]},*/
    {id: 2, name: 'Withdraw', icon: BadgeTwoToneIcon, route: '/user/withdraw', subItems: [ {id: 0, name: 'Withdraw Now', icon: CircleTwoToneIcon, route: '/user/withdraw'}, {id: 1, name: 'Withdraw History', icon: CircleTwoToneIcon, route: '/user/withdraw/history'}]},
    {id: 3, name: 'Miners', icon: CellWifiIcon, route: '/user/miners', subItems : null},
    {id: 4, name: 'My Referrals', icon: GroupAddIcon, route: '/user/my-refs', subItems: null},
    /*{{id: 4, name: 'Transactions', icon: PaymentsTwoToneIcon, route: '/user/transactions', subItems : null},
    {id: 5, name: 'Help & Support', icon: HelpTwoToneIcon, route: '/user/ticket', subItems: [ {id: 0, name: 'Support tickets', icon: CircleTwoToneIcon, route: '/user/ticket'}, {id: 1, name: 'New ticket', icon: CircleTwoToneIcon, route: '/user/ticket/new'}]},*/
    {id: 6, name: 'Logout', icon: ExitToAppTwoToneIcon, route: 'none', subItems : null},
]



export default function NavUser({children}) {
  const theme = useTheme();
  const matches = useMediaQuery('(max-width:900px)');
  const [open, setOpen] = React.useState(true);
  const [openMob, setOpenMob] = React.useState(false);
  const navigate = useNavigate();
  const [openSub, setOpenSub] = React.useState(null);
  const [selected, setSelected] = React.useState(null);
  const container = window !== undefined ? () => window.document.body : undefined;

  const dispatch = useDispatch();
  const logOut = async () => {
    try{
      const response = await axiosInstance.post('auth/logout');
      if(response.data.success){
        dispatch(logOutAuth())
        navigate('/login');
      }
    }catch{
      dispatch(logOutAuth())
      navigate('/login');
    }
  }
  React.useEffect(() => {
    setOpen(matches ? false : true)
  }, [matches])
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const location = useLocation();

  React.useEffect(() => {
    const isSub = location.pathname.toString().split('/')[3];
    setSelected(location.pathname.toString());
    if(isSub){
        setOpenSub(removeLast(location.pathname.toString()))
    }else{
        setOpenSub(location.pathname.toString())
    }
  }, [location])

  //const handleDrawerOpen = () => {
  //  setOpen(true);
  //};

  //const handleDrawerClose = () => {
  //  setOpen(false);
  //};

  function capitalizeFirstLetter(string) {
        const isSub = string.toString().split('/')[3];
        let path = string;
        let other = '';
        if(isSub){
            path = removeLast(location.pathname.toString());
        }

        const result = menuItems.flatMap(item => item.subItems).filter(item => item?.route === string);
    
        if(result[0]?.name){
            other = result[0].name;
            return other;
        }

        let index = menuItems.findIndex(x => {return x.route === path});
        
        if(index !== -1){
            return menuItems[index].name;
        }else{
          let indexOhter = settings.findIndex(x => {return x.route === path});
          if(indexOhter !== -1){
            if(settings[indexOhter].name === 'Logout'){
              logOut();
              return;
            }
            return settings[indexOhter].name;
          }
          else navigate('/user/dashboard');
        }
  }

  function removeLast(string){
    try{
        const words = string.split("/");

        words[words.length-1] = '';

        const res = words.join('/');
        return res.slice(0, -1);
    }catch{
        return '';
    }
  }

  function DrawerInside(){
    return(
      <>
      <DrawerHeader>
        {openMob &&
          <div className="display_center" style={{width: '100%', flexDirection: 'row'}}>
            <div style={{cursor: 'pointer', display: 'flex', justifyContent: 'center'}} onClick={() => navigate('/')}>
              <img src={logo} alt="logo" style={{width: '50%'}} />
            </div>
            <IconButton onClick={() => setOpenMob(false)}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </div>
        }
        {!matches && !openMob &&
          <div className="display_center" style={{width: '100%'}}>
            <div style={{cursor: 'pointer', display: 'flex', justifyContent: 'center'}} onClick={() => navigate('/')}>
              <img src={logo} alt="logo" style={{width: '40%'}} />
            </div>
          </div>
        }
        </DrawerHeader>
        <Divider />
        <List>
            {menuItems.slice(0, 3).map(x =>
                <React.Fragment key={x.id}>
                    <ListItem onClick={() => x.subItems ? setOpenSub(openSub === x.route ? null : x.route) : x.route === 'none' ? logOut() : navigate(x.route)} selected={openSub === x.route} disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <x.icon />
                            </ListItemIcon>
                            <ListItemText primary={x.name} />
                            {x.subItems && (openSub === x.route ? <ExpandLess /> : <ExpandMore />)}
                        </ListItemButton>
                    </ListItem>

                    {x.subItems &&
                    <Collapse in={openSub === x.route} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {x.subItems.map(y=> 
                                <ListItemButton selected={selected === y.route} key={y.id} onClick={() => y.route === 'none' ? logOut() : navigate(y.route)} sx={{ pl: 4 }}>
                                    <ListItemIcon>
                                        <y.icon />
                                    </ListItemIcon>
                                    <ListItemText primary={y.name} />
                                </ListItemButton>
                            )}
                        </List>
                    </Collapse>
                    }
                </React.Fragment>    
            )}
        </List>
        <Divider />
        <List>
            {menuItems.slice(3, menuItems.length).map(x =>
                <React.Fragment key={x.id}>
                    <ListItem onClick={() => x.subItems ? setOpenSub(openSub === x.route ? null : x.route) : x.route === 'none' ? logOut() : navigate(x.route)} selected={openSub === x.route} disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <x.icon />
                            </ListItemIcon>
                            <ListItemText primary={x.name} />
                            {x.subItems && (openSub === x.route ? <ExpandLess /> : <ExpandMore />)}
                        </ListItemButton>
                    </ListItem>

                    {x.subItems &&
                    <Collapse in={openSub===x.route} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {x.subItems.map(y=> 
                                <ListItemButton selected={selected === y.route} key={y.id} onClick={() => navigate(y.route)} sx={{ pl: 4 }}>
                                    <ListItemIcon>
                                        <y.icon />
                                    </ListItemIcon>
                                    <ListItemText primary={y.name} />
                                </ListItemButton>
                            )}
                        </List>
                    </Collapse>
                    }
                </React.Fragment>    
            )}
        </List>
        </>
    )
  }

  return (
    <Box sx={{ display: 'flex', flex: 1 }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{backgroundColor: 'white'}} open={open} matches={matches}>
        <Toolbar sx={{justifyContent: "space-between", flexWrap: 'wrap'}}>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => setOpenMob(true)}
                    edge="start"
                    sx={{ mr: 2, ...(!matches && { display: 'none' }) }}
                >
                    <MenuIcon />
                </IconButton>
                {!matches &&
                <Typography variant="h6" noWrap component="div">
                    {selected && <span>{capitalizeFirstLetter(selected)}</span>}
                </Typography>
                }
            </div>

            <div style={{display: 'flex', alignItems: 'center', gap: '.5rem', flexWrap: 'wrap'}}>
              {!matches && <div style={{display: 'flex', alignItems: 'center', gap: '.5rem', flexWrap: 'wrap'}}>
                  <Button onClick={() => window.open('https://web.telegram.org/k/#@LjubomirHashCash', '_blank')}  variant="outlined" className="btn_user1"><SendOutlinedIcon className="me-2"/> Telegram Admin</Button>
              </div>}
              <Button onClick={() => window.open('https://t.me/+NDWuYkNaLG83M2Ux', '_blank')} style={{color: 'black', borderColor: 'black'}} variant="outlined" className="btn_user1"><TelegramIcon className="me-2"/> {matches ? "TG" : "Telegram Chanel"}</Button>
              <Button onClick={() => window.open('/plans', '_blank')} variant="outlined" className="me-3 btn_user1"><CellWifiIcon className="me-2" /> Rent Miners</Button>
              <Box sx={{ flexGrow: 0 }}>
                      <Tooltip title="Open settings">
                      <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                          <Avatar style={{border: '1px solidblack'}} alt="Remy Sharp" src="/logo.png" />
                      </IconButton>
                      </Tooltip>
                      <Menu
                      sx={{ mt: '45px' }}
                      id="menu-appbar"
                      anchorEl={anchorElUser}
                      anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                      }}
                      keepMounted
                      transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                      }}
                      open={Boolean(anchorElUser)}
                      onClose={handleCloseUserMenu}
                      >
                      {settings.map((setting) => (
                          <MenuItem key={setting.id} onClick={() => {navigate(setting.route)}}>
                            <Typography textAlign="center">{setting.name}</Typography>
                          </MenuItem>
                      ))}
                      </Menu>
                  </Box>
            </div>
        </Toolbar>
      </AppBar>
      <Drawer
          container={container}
          variant="temporary"
          open={openMob}
          onClose={() => setOpenMob(false)}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'block' , md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          <DrawerInside/>
      </Drawer>

      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
          display: { xs: 'none', sm: 'none', md: 'block' },
        }}
        variant="permanent"
        anchor="left"
        open={open}
      >
        <DrawerInside/>
      </Drawer>
      <Main style={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` }, backgroundColor: '#F0F2F5', flex: '1'}} matches={matches} open={open}>
        <DrawerHeader/>
        {children}
      </Main>
    </Box>
  );
}