import * as React from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Grid from '@mui/material/Grid';
import { useSelector } from 'react-redux';

export default function Profile(){
    const {user} = useSelector(state => state.auth);

    return(
        <div className="dashboard_page">
            <Container maxWidth="lg">
                <Paper className="paper_card pt-3 pb-3 ps-3 pe-3" variant="elevation" elevation={6}>
                        <Form className="pt-4 pb-3">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <Form.Group className="mb-3" controlId="profileForm.LastName">
                                        <Form.Label>Username</Form.Label>
                                        <InputGroup hasValidation>
                                            <Form.Control value={user ? user.username: ""} disabled type="text" placeholder="" />
                                        </InputGroup>  
                                    </Form.Group>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Form.Group className="mb-3" controlId="profileForm.LastName">
                                        <Form.Label>ID</Form.Label>
                                        <InputGroup hasValidation>
                                            <Form.Control value={user ? user._id: ""} disabled type="text" placeholder="" />
                                        </InputGroup>  
                                    </Form.Group>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <Form.Group className="mb-3" controlId="profileForm.email">
                                        <Form.Label>Email</Form.Label>
                                        <InputGroup hasValidation>
                                            <Form.Control value={user ? user.email: ""} disabled type="email" placeholder="" />
                                        </InputGroup>  
                                    </Form.Group>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Form.Group className="mb-3" controlId="profileForm.Telegram">
                                        <Form.Label>Telegram Username</Form.Label>
                                        <InputGroup hasValidation>
                                            <Form.Control value={user ? user.telegram: ""} disabled type="text" placeholder="" />
                                        </InputGroup> 
                                    </Form.Group>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <Form.Group className="mb-3" controlId="profileForm.Address">
                                        <Form.Label>Balance</Form.Label>
                                        <InputGroup hasValidation>
                                            <Form.Control disabled value={user ? user.balance: ""} type="text" placeholder="" />
                                        </InputGroup>  
                                    </Form.Group>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Form.Group className="mb-3" controlId="profileForm.State">
                                        <Form.Label>Ref Code</Form.Label>
                                        <InputGroup hasValidation>
                                            <Form.Control value={user ? user.ref_code: ""} disabled type="text" placeholder="" />
                                        </InputGroup> 
                                    </Form.Group>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <Form.Group className="mb-3" controlId="profileForm.State">
                                        <Form.Label>Country</Form.Label>
                                        <InputGroup hasValidation>
                                            <Form.Control value={user ? user.country: ""} type="text" disabled placeholder="" />
                                        </InputGroup> 
                                    </Form.Group>
                                </Grid>
                            </Grid>
                            
                            {/** 
                            <div className="display_start">
                                <Button type="submit" className="btn_about full-width">Submit</Button>
                            </div>*/}
                        </Form>
                </Paper>
            </Container>
        </div>
    )
}