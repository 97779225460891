import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

const parts = [
    {id: 0, title: 'Impressions', value: 20000000, color: '#000AF5'},
    {id: 1, title: 'Users', value: 50000, color: '#B400F5'},
    {id: 2, title: 'Withdraws', value: 750000, color: '#00F50A'},
    {id: 3, title: 'Miners Used', value: 568000, color: '#F55800'},
]

function nFormatter(num, digits) {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "K" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" }
    ];
    const regexp = /\.0+$|(?<=\.[0-9]*[1-9])0+$/;
    const item = lookup.findLast(item => num >= item.value);
    return item ? (num / item.value).toFixed(digits).replace(regexp, "").concat(item.symbol) : "0";
  }
export default function StatsHome(){
    return(
        <div className="stats_component pt-5 pb-5">
            <Container>
                <div className="display_center">
                    <h1>HashCash Dubai in Numbers</h1>
                </div>
                <div className="display_center">
                    <Grid className="pt-5 pb-4" container spacing={2}>
                        {parts.map(p =>
                            <Grid key={p.id} item xs={6} sm={12/parts.length} md={12/parts.length}>
                                <div className="numbers_stats_home">{nFormatter(p.value).slice(0, -1)}
                                    <span style={{color: p.color}}>{nFormatter(p.value).slice(-1)}</span>
                                </div>
                                <div className="stats_home_title">{p.title}</div>
                            </Grid>
                        )}
                    </Grid>
                </div>
            </Container>
        </div>
    )
}