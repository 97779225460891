import { useNavigate } from "react-router-dom";

import Grid from '@mui/material/Grid';
import Container from 'react-bootstrap/Container';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider'

import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';

import icon from '../assets/img/logo_small.png';
export default function Footer(){
    const navigate = useNavigate();

    const handleChange = (newValue)  => {
        navigate('/'+newValue);
    }
    return(
        <div className="footer_container">
            <Container>
                <Grid container spacing={5}>
                    <Grid className="pt-0" item xs={12} sm={6}>
                        <div className="pb-4" style={{cursor: 'pointer', display: 'flex', justifyContent: 'start'}} >
                            <img src={icon} alt="logo" onClick={() => navigate('/')} style={{width: '8rem'}} />
                        </div>
                        <Divider className="mt-1 mb-3" />
                        <div className="text-grayed text-sm">© 2024 HashCash Mining Dubai Foundation. All rights reserved.</div>
              
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <h6 className="bold_h6">Quick Link</h6>
                                <div className="gap-column-sm pt-2">
                                    <div onClick={() => handleChange('plans')} className="link_footer">Plans</div>
                                    <div onClick={() => handleChange('about')} className="link_footer">About</div>
                                    <div onClick={() => handleChange('contact')} className="link_footer">Contact</div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <h6 className="bold_h6">Contact</h6>
                                <List>
                                    <ListItem className="pb-2" disablePadding>
                                            <ListItemIcon>
                                                <PlaceOutlinedIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Al Thamaneen St - Dubai Marina - Dubai - United Arab Emirates" />
                                    </ListItem>
                                    <ListItem disablePadding>
                                            <ListItemIcon>
                                                <EmailOutlinedIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="contact@hashcash.finance" />
                                    </ListItem>
                                </List>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
            
        </div>
    )
}