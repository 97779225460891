import {useState, useEffect} from 'react';
import Container from '@mui/material/Container';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import Grid from '@mui/material/Grid';
import Form from 'react-bootstrap/Form';
import Button from '@mui/material/Button';

export default function Contact(){
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');

    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    useEffect(() => {
        setError('');
        setSuccess('');
    }, [name, email, subject, message])

    useEffect(() => {
        if(success !== ''){
            setError('');
        }
    }, [success])

    const checkValidate = () => {
        return name !== '' && email !== ''&& subject !== ''&& message !== '';
    }

    const submit = async (e) => {
        e.preventDefault();

        if(checkValidate()){
               setSuccess("Form submited successfully")
        }else{
            setError("Fill in the form")
        }
    }

    return(
        <div className="contact_page">
            <Container className="container_mining1 about_component pb-5 pt-5 mt-5 text-center" style={{background: 'transparent', border: 'none', borderRadius: '0px'}}>
                <Grid container spacing={2}>

                    <Grid item xs={12} sm={6}>
                        <div className="pb-5 mb-3">
                            <h1 style={{background: 'hls(0, 166, 245)', borderRadius: '0px'}}>Contact Us</h1>
                            <p className="p_contact">Contact us if you have any questions, suggestions, feedback or complaints. You can reach us by email or contact form. We are always happy to hear from you and we will do our best to respond as soon as possible.</p>

                            <div>
                            <List>
                                <ListItem className="pb-2" disablePadding>
                                        <ListItemIcon>
                                            <PlaceOutlinedIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Al Thamaneen St - Dubai Marina - Dubai - United Arab Emirates" />
                                </ListItem>
                                <ListItem disablePadding>
                                        <ListItemIcon>
                                            <EmailOutlinedIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="contact@hashcash.finance" />
                                </ListItem>
                            </List>
                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <div className="contact_form">
                        <Form>
                            <Form.Group className="mb-3" controlId="contactForm.name">
                                <Form.Label>Name <span>*</span></Form.Label>
                                <Form.Control value={name} onChange={e => setName(e.target.value)} type="text" placeholder="" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="contactForm.email">
                                <Form.Label>Email <span>*</span></Form.Label>
                                <Form.Control value={email} onChange={e => setEmail(e.target.value)} type="email" placeholder="" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="contactForm.subject">
                                <Form.Label>Subject <span>*</span></Form.Label>
                                <Form.Control value={subject} onChange={e => setSubject(e.target.value)} type="text" placeholder="" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="contactForm.message">
                                <Form.Label>Message <span>*</span></Form.Label>
                                <Form.Control value={message} onChange={e => setMessage(e.target.value)} as="textarea" rows={3} />
                            </Form.Group>
                            <div className="display_start">
                                <Button type="submit" onClick={e => submit(e)} className="btn_about">Submit</Button>
                            </div>
                            {error && <p className="text_error">{error}</p>}
                            {success && <p className="text_success">{success}</p>}
                        
                        </Form>
                        </div>
                    </Grid>

                </Grid>
               
            </Container>
        </div>
    )
}