import PlansComponent from '../components/PlansComponent';
import Container from '@mui/material/Container';
export default function Plans(){

    return(
        <div className="pt-5 mt-5">
            <Container className="container_mining1 about_component pb-5 mt-3 text-center" style={{background: 'transparent', border: 'none'}}>
                <p>Our Best Plans</p>
                <div className="display_center pb-5 mb-3">
                    <h1>Unleash Your Earning Power</h1>
                </div>

                <PlansComponent />
            </Container>
        </div>
    )
}