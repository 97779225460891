import { useEffect, useState, useCallback } from 'react';
import axiosInstance from '../../redux/axios';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Container from '@mui/material/Container';

const columns = [
    { field: '_id', headerName: 'User ID', width: 250 },
    { field: 'username', headerName: 'Username', width: 200 },
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'lastmined', headerName: 'Last Minted', width: 230},
    { field: 'miners', headerName: 'Miners', width: 530},
];

const changeFormat = (date) => {
    const date1 = new Date(date);
  
    const options = {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false, // Use 24-hour format
    };
  
    const formattedDate = date1.toLocaleString('en-US', options);
    const formattedDateWithoutAMPM = formattedDate.replace(/, /g, ' ').replace(/:\d{2} (AM|PM)/, '');
    return formattedDateWithoutAMPM;
}
  

export default function MyRefferals({user}){
    const [users, setUsers] = useState([]);
    
    const getUsers = useCallback(
      async () => {
        try{
            const response = await axiosInstance.get('myref');
          
            if(response.data){
                console.log(response.data)
                const updatedRows = response.data.map(row => ({
                    ...row,
                    lastmined: changeFormat(row.lastmined),
                    miners: row.miners.map((miner, index) => `${index + 1}. Miner: ${miner.type} USDC`).join('\n'),
                }));
                setUsers(updatedRows);
                console.log(users)
            }
        }catch{}
        }
      ,[]
    )
    
    useEffect(() => {
      getUsers()
    }, [getUsers])
    
    return(
        <div>
            <Container>
            <h3 className="pt-4" style={{color: 'black', fontWeight: 600}}>My Referral Users</h3>
            <p className="pb-4" style={{color: 'black'}}>Getting 10% of each user's miners</p>
                <div style={{ overflow: 'scroll', maxWidth: '80vw'}}>
                    <DataGrid
                        getRowId={(row: any) =>  row._id}
                        rows={users}
                        columns={columns}
                        initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 5 },
                        },
                        }}
                        pageSizeOptions={[5, 10]}
                        slots={{ toolbar: GridToolbar }}
                        slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                        },
                        }}
                        autoHeight
                    />
                </div>
            </Container>
        </div>
    )
}