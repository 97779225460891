import * as React from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

const columns = [
  { field: 'tx', headerName: 'Gateway | Transaction', width: 250 },
  { field: 'date', headerName: 'Initiated', width: 200 },
  { field: 'amount', headerName: 'Amount', width: 130, type: 'number' },
  {
    field: 'status',
    headerName: 'Status',
    width: 130,
    renderCell: (params) => {
      const cellStyle = {
        color: params.value === 'pending' ? 'gray' : 'green',
      };
      return <div style={cellStyle}>{params.value}</div>;
    },
  },
  {
    field: 'hash',
    headerName: 'Hash',
    width: 300,
  },
];

const changeFormat = (date) => {
  const date1 = new Date(date);

  const options = {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false, // Use 24-hour format
  };

  const formattedDate = date1.toLocaleString('en-US', options);
  const formattedDateWithoutAMPM = formattedDate.replace(/, /g, ' ').replace(/:\d{2} (AM|PM)/, '');
  return formattedDateWithoutAMPM;
}


export default function WHistory({withdraws, setWithdraw}) {

  const updateColumnValue = () => {
    const updatedRows = withdraws.map(row => ({
      ...row,
      date: changeFormat(row.date),
    }));
    setWithdraw(updatedRows);
  };

  React.useEffect(() => {
    // Example: Update the 'age' column value to 40 for all rows after component mounts
    updateColumnValue();
  }, []);

  return (
    <div style={{ overflow: 'scroll', maxWidth: '80vw'}}>
      <DataGrid
        getRowId={(row: any) =>  row.date + row.amount}
        rows={withdraws}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        autoHeight
      />
    </div>
  );
}
