import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';

import '../assets/css/Plans.css';


const plans = [
    {id: 0, init: 0, price: 132 , miner: 'XMR Miner X3', img: '/static/machines/x5.png', name: 'x0.5', pack: 'Common', color: 'rgb(9 93 57)', di: 5},
    {id: 1, init: 0, price: 282 , miner: 'XMR Miner X5', img: '/static/machines/x5.png', name: 'x1', pack: 'Standard', color: '#647D97', di: 10},
    {id: 2, init: 7, price: 564 , miner: 'HNS Miner HS3', img: '/static/machines/HS3.png' ,name: 'x2', pack: 'Gold', color: '#DC8707', di: 20},
    {id: 3, init: 14, price: 1690 ,miner: 'MICROBT WhatsMiner M50S++', img: '/static/machines/M50S.png', name: 'x6', pack: 'Platinum', color: '#0094FF', di: 34},
    {id: 4, init: 21, price: 2180 ,miner: 'Zcash Miner Z15 Pro', img: '/static/machines/Z15.png', name: 'x20', pack: 'Diamond', color: '#30D891', di: 50},
    {id: 5, init: 28, price: 2780 ,miner: 'Miner T21', img: '/static/machines/T21.png', name: 'x30', pack: 'VIP1',  color: '#F36EFF', di: 67},
    {id: 6, init: 35, price: 3150 ,miner: 'ETC Miner E9 Pro', img: '/static/machines/e9.png', name: 'x60', pack: 'VIP2',color: '#D4A70C', di: 100},
    {id: 7, init: 42, price: 6620 ,miner: 'Miner S21 Hyd.', img: '/static/machines/S21.png', name: 'x280', pack: 'VIP3', color: '#FF7C74', di: 200},
]

export default function PlansUser({minersUser}){

    const getUntil = (created_at, days_rent) => {
        try{
            return new Date(new Date(created_at).getTime() + Number(days_rent) * 24 * 60 * 60 * 1000).toISOString()
        }catch{
            return created_at;
        }
    }

    const options = {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false, // Use 24-hour format
    };
    

    const changeFormat = (date) => {
        const date1 = new Date(date);

        const formattedDate = date1.toLocaleString('en-US', options);
        const formattedDateWithoutAMPM = formattedDate.replace(/, /g, ' ').replace(/:\d{2} (AM|PM)/, '');
        return formattedDateWithoutAMPM;
    }
  
    return(
        <Grid container spacing={5}>
            {minersUser && minersUser.map((x,i) =>
                <Grid item key={x._id} xs={12} sm={6} md={4}>
                    <div className="plan_item">
                        <div className="plan_h1" style={{color: plans.find(y=> {return y.di === x.type})?.color}}>{plans.find(y=> {return y.di === x.type})?.miner}</div>
                        <div className="plan_l">{x?.minerType?.description}</div>

                        <div className="plan_price" style={{color: plans.find(y=> {return y.di === x.type})?.color}}>{x?.minerType?.price}<span>/USDC</span></div>
                        
                        <Divider/>

                        <div className="plan_features" style={{color: 'black'}}>
                            <div>
                                <img src={'/static/machines/'+x?.minerType?.imageurl+'.png'} alt='mach' />
                            </div>
                            <div><CheckCircleOutlineOutlinedIcon style={{color: plans.find(y=> {return y.di === x.type})?.color}}/>Hash Rate : {plans.find(y=> {return y.di === x.type})?.name}</div>
                            <div><CheckCircleOutlineOutlinedIcon style={{color: plans.find(y=> {return y.di === x.type})?.color}}/>Plan Price : {x?.minerType?.price} USDC</div>
                            <div><CheckCircleOutlineOutlinedIcon style={{color: plans.find(y=> {return y.di === x.type})?.color}}/>Daily income: {x.type} USDC</div>
                            <div><CheckCircleOutlineOutlinedIcon style={{color: plans.find(y=> {return y.di === x.type})?.color}}/>Weekly income: {(x.type * 7).toFixed(2)} USDC</div>
                            <div><CheckCircleOutlineOutlinedIcon style={{color: plans.find(y=> {return y.di === x.type})?.color}}/>Monthly income: {(x.type * 30).toFixed(2)} USDC</div>
                            <div><CheckCircleOutlineOutlinedIcon style={{color: plans.find(y=> {return y.di === x.type})?.color}}/>Valid for : {x?.days_rent} days</div>
                        </div>
                    </div>
                </Grid>
            )}
        </Grid>
    )
}