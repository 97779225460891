import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import {useState} from 'react';

import metamask_1 from '../assets/img/metamask_1.jpg';
import metamask_2 from '../assets/img/metamask_2.jpg';

import binance_1 from '../assets/img/binance_1.png';
import binance_2 from '../assets/img/binance_2.png';
import binance_3 from '../assets/img/binance_3.png';
export default function Tutorial(){
    const [selected, setSelected] = useState('metamask');
    return(
        <div className="pt-5 pb-5">
            <Container>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <ButtonGroup size="small" aria-label="Toggle-tutorial" className="toggle_tutorial">
                        <Button onClick={() => setSelected('metamask')} variant={selected === 'metamask' ? 'contained': 'outlined'}>Decentralized Wallet (Metamask)</Button>
                        <Button onClick={() => setSelected('binance')} variant={selected === 'binance' ? 'contained': 'outlined'}>Centralized Wallet (Binance)</Button>
                    </ButtonGroup>
                </div>

                <div className="pt-4">
                {selected === 'metamask' && <>
                    <h3 className="pt-2">
                        How to find and copy transaction hash (Txn Hash) in MetaMask?
                    </h3>

                    <p>Open the MetaMask extension.</p>
                    <p>1. Go to the Activity tab and click on the transaction you are interested in.</p>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <img style={{width: '15rem'}} src={metamask_1} alt="metamask_1" />
                    </div>
                    <p>2. Copy Transaction Hash clicking on Copy transaction ID link or View on block explorer.</p>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <img style={{width: '15rem'}} src={metamask_2} alt="metamask_2" />
                    </div>

                </>}
                {selected === 'binance' && <>
                    <h3 className="pt-2">
                        How to find and copy transaction hash (Txn Hash) on Binance?
                    </h3>

                    <p>
                        You can find your TxID from the platform you made a withdrawal. 
                        For example, you can find the TxID from [Transaction History] on Binance, or through the following steps.
                    </p>
                    <p>1. Log in to your Binance account. Click [Wallet] and select [Overview] from the drop-down menu.</p>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <img className="pt-2 pb-2" style={{width: '90%'}} src={binance_1} alt="binance_1" />
                    </div>
                    <p>2. Click [Transaction History]. Here you can see all your deposit or withdrawal history. You can find the TxID under [TxID].</p>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <img className="pt-2 pb-2" style={{width: '90%'}} src={binance_2} alt="binance_2" />
                    </div>
                    <p>You can also access your transaction history from [Wallets] - [Spot] - [Transaction History] on the Binance App. Tap on the relevant transaction to find the TxID.</p>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <img className="pt-2 pb-2" style={{width: '90%'}} src={binance_3} alt="binance_3" />
                    </div>
                </>}
                </div>
            </Container>
        </div>
    )
}