import '../assets/css/About.css';
import AboutComponent from '../components/AboutComponent';
import Container from '@mui/material/Container';
import DigitalMinersHome from '../components/DigitalMinersHome';

export default function About(){
    return(
        <div>
            <AboutComponent />
            

            <div className="pt-1 mt-5">
                <Container className="container_mining1 about_component pb-5 mt-3 text-center" style={{background: 'transparent', border: 'none'}}>
                   <DigitalMinersHome />
                </Container>
            </div>
        </div>
    )
}