import { useState } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Slider, { SliderThumb } from '@mui/material/Slider';

import { styled } from '@mui/material/styles';
import SyncIcon from '@mui/icons-material/Sync';

import LeaderboardOutlinedIcon from '@mui/icons-material/LeaderboardOutlined';
import SettingsInputSvideoIcon from '@mui/icons-material/SettingsInputSvideo';
import TokenOutlinedIcon from '@mui/icons-material/TokenOutlined';
import DiamondOutlinedIcon from '@mui/icons-material/DiamondOutlined';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import StarHalfOutlinedIcon from '@mui/icons-material/StarHalfOutlined';
import StarOutlinedIcon from '@mui/icons-material/StarOutlined';

import mining from '../assets/img/mining.png';

const PrettoSlider = styled(Slider)({
    color: '#52af77',
    height: 8,
    '& .MuiSlider-track': {
      border: 'none',
      height: '14px'
    },
    '& .MuiSlider-rail': {
        height: '14px'
    },
    '& .MuiSlider-thumb': {
      height: 30,
      width: 30,
      backgroundColor: '#fff',
      border: '2px solid currentColor',
      '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
        boxShadow: 'inherit',
      },
      '&::before': {
        display: 'none',
      },
    },
    '& .MuiSlider-valueLabel': {
        display: 'none',
    },
});

function valuetext(value) {
    return `${value}°C`;
}

function AirbnbThumbComponent(props) {
    const { children, ...other } = props;
    return (
      <SliderThumb {...other}>
        {children}
        <SyncIcon className="rotating" />
      </SliderThumb>
    );
}

const marks1 = [
    {id: 0, init: 0, name: 'x0.5', pack: 'Common', icon: SettingsInputSvideoIcon, color: 'rgb(9, 93, 57)', di: 5},
    {id: 1, init: 7, name: 'x1', pack: 'Standard', icon: SettingsInputSvideoIcon, color: '#647D97', di: 10},
    {id: 2, init: 14, name: 'x2', pack: 'Gold', icon: LeaderboardOutlinedIcon, color: '#DC8707', di: 20},
    {id: 3, init: 21, name: 'x6', pack: 'Platinum', icon: TokenOutlinedIcon, color: '#0094FF', di: 34},
    {id: 4, init: 28, name: 'x20', pack: 'Diamond', icon: DiamondOutlinedIcon, color: '#30D891', di: 50},
    {id: 5, init: 35, name: 'x30', pack: 'VIP1', icon: StarBorderOutlinedIcon, color: '#F36EFF', di: 67},
    {id: 6, init: 42, name: 'x60', pack: 'VIP2', icon: StarHalfOutlinedIcon, color: '#D4A70C', di: 100},
    {id: 7, init: 48, name: 'x280', pack: 'VIP3', icon: StarOutlinedIcon, color: '#FF7C74', di: 200},
]

export default function MiningCalculator(){
    const [value, setValue] = useState(0);
 
    const handleChange = (event, newValue) => {
        if (typeof newValue === 'number') {
          setValue(newValue);
        }
    };
    
    return(
        <Container className="container_mining">
            <div>
                <div className="display_spacebetween header_mining">
                    <div>Hash Rate: {marks1.filter(x => {return x.init === value}).map(x => <span>{x.name}</span>)} </div>
                    <div><img className="mining_img" src={mining} alt="mining" /> </div>
                </div>
                <PrettoSlider
                    value={value}
                    onChange={handleChange}
                    aria-label="Temperature"
                    slots={{ thumb: AirbnbThumbComponent }}
                    defaultValue={0}
                    getAriaValueText={valuetext}
                    valueLabelDisplay="off"
                    shiftStep={7}
                    step={7}
                    min={0}
                    max={48}
                    //marks={marks}
                    color="secondary"
                />
                <div className="marks">
                    {marks1.map(x => 
                        <div className={x.init === value ? 'selected_mark' : ''} onClick={() => {setValue(x.id*7)}} key={x.id}>
                            <div>{x.name}</div>
                            {x.icon && <div className="icon_marks" style={{backgroundColor: x.color}}><x.icon /></div>}
                            <div> {x.pack} </div>
                        </div>
                    )}
                </div>
            </div>
            
            <div className="position-relative">
                <Grid className="grid_mining" container spacing={1}>
                    <Grid item xs={12} sm={6} md={4}>
                        <p className="grid_label">Daily income</p>
                        <div className="grid_value">{marks1.filter(x => {return x.init === value}).map(x => <span>{x.di}</span>)} USDC</div>
                        <p className="grid_subvalue">≈{marks1.filter(x => {return x.init === value}).map(x => <span>{(x.di + 0.01).toFixed(2)}</span>)} USD</p>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <p className="grid_label">Weekly income</p>
                        <div className="grid_value">{marks1.filter(x => {return x.init === value}).map(x => <span>{x.di*7}</span>)} USDC</div>
                        <p className="grid_subvalue">≈{marks1.filter(x => {return x.init === value}).map(x => <span>{((x.di*7) + 0.01).toFixed(2)}</span>)} USD</p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <p className="grid_label">30-day income</p>
                        <div className="grid_value">{marks1.filter(x => {return x.init === value}).map(x => <span>{x.di*30}</span>)} USDC</div>
                        <p className="grid_subvalue">≈{marks1.filter(x => {return x.init === value}).map(x => <span>{((x.di*30) + 0.01).toFixed(2)}</span>)} USD</p>
                    </Grid>
                </Grid>
            </div>
        </Container>
    )
}