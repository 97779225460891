import React, { useEffect, useState, useRef } from 'react';
import '../../assets/css/Terminal.css';

const Terminal = ({workIf}) => {
    const [lines, setLines] = useState([]);
    const terminalRef = useRef(null);

    const getRandomNumber = (min, max) => {
        return (Math.random() * (max - min) + min).toFixed(10);
    };
    
    const terminalMessages = [
        "Starting mining operation...",
        "Connecting to Ethereum network...",
        "Fetching latest block...",
        "Validating transactions...",
        "Mining new block...",
        "Block mined successfully! Block hash: 0x" + Math.random().toString(16).substr(2, 8),
        "Broadcasting block to network...",
        "Waiting for next block...",
        "Synchronizing with peers...",
        "Transaction received: 0x" + Math.random().toString(16).substr(2, 8),
        "New transaction pool size: " + Math.floor(Math.random() * 100),
        "Mining difficulty adjusted: " + Math.floor(Math.random() * 1000000),
        `Reward received: ${getRandomNumber(0.0000000001, 0.0000000005)} DogeCoin `,
        "Checking node status...",
        "Node synchronized. Current block: " + Math.floor(Math.random() * 1000000),
        `Reward received: ${getRandomNumber(0.000000001, 0.000000005)} Kaspa`,
    ];

    useEffect(() => {
        if (terminalRef.current) {
            terminalRef.current.scrollTop = terminalRef.current.scrollHeight;
        }
    }, [lines]);

    useEffect(() => {
        let interval = null;
        if(workIf){
            setLines([]);
            interval = setInterval(() => {
                setLines(prevLines => {
                    const newLine = terminalMessages[Math.floor(Math.random() * terminalMessages.length)];
                    return [...prevLines, '[' + new Date(Date.now()).toLocaleString().replaceAll(',','') + ']  ' + newLine];
                });
            }, 1000); // Add a new line every second
        }else{
            setLines(["No miners detected to start the proccess..."])
        }
        return () => clearInterval(interval);
    }, [workIf]);

    return (
        <div className="terminal" ref={terminalRef}>
            {lines && lines.map((line, index) => (
                <div key={index} className="terminal-line">{line}</div>
            ))}
        </div>
    );
};

export default Terminal;