import * as React from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Form from 'react-bootstrap/Form';
import Button from '@mui/material/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';

const methods = [
    {id: 0, name: 'Low'},
    {id: 1, name: 'Medium'},
    {id: 2, name: 'High'}
]

function File({times, removeAttachment}) {
    return (
        <>
            <Form.Group controlId="formFile" className="mb-3">
                <Form.Control type="file" />
            </Form.Group>
        </>
    )
}

export default function NewTicket(){
    const [amount, setAmount] = React.useState(1);

    function addAttachment(){
        if(amount<5){
            setAmount(amount+1);
        }
    }

    function removeAttachment(){
        if(amount > 1){
            setAmount(amount-1)
        }
    }
    return(
        <div className="dashboard_page">
            <Container maxWidth="lg">
                <Paper className="paper_card pt-3 pb-3 ps-3 pe-3" variant="elevation" elevation={6}>
                    <h4>Open a ticket</h4>
                        <Form className="pt-4 pb-3">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <Form.Group className="mb-3" controlId="ticketForm.Subject">
                                        <Form.Label>Subject <span>*</span></Form.Label>
                                        <InputGroup hasValidation>
                                            <Form.Control type="text" placeholder="" />
                                        </InputGroup>  
                                    </Form.Group>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Form.Group className="mb-3" controlId="ticketForm.Priority">
                                        <Form.Label>Priority <span>*</span></Form.Label>
                                        <Form.Select aria-label="countries">
                                            {methods.map(m =>
                                                <option key={m.id} value={m.value}>{m.name}</option>
                                            )}
                                        </Form.Select>
                                    </Form.Group>
                                </Grid>
                            </Grid>
                            
                            <Form.Group className="mb-3" controlId="ticketForm.message">
                                <Form.Label>Message <span>*</span></Form.Label>
                                <Form.Control as="textarea" rows={3} />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="ticketForm.Attachments">
                                    <div className="display_spacebetween">
                                        <Form.Label>
                                            Attachments <span> Max 5 files can be uploaded. Maximum upload size is 2MB </span>
                                        </Form.Label>

                                        <div className="display_spacebetween" style={{gap: '.3rem'}}>
                                            <Avatar sx={{cursor: 'pointer', backgroundColor: 'green'}}  onClick={() => addAttachment()}>
                                                +
                                            </Avatar>
                                            <Avatar sx={{cursor: 'pointer', backgroundColor: 'red'}} onClick={() => removeAttachment()}>
                                                -
                                            </Avatar>
                                        </div>
                                    </div>
                            </Form.Group>

                            {Array.apply(null, { length: amount }).map((e, i) => (
                                <span key={i}>
                                    <File times={i} removeAttachment={removeAttachment} />
                                </span>
                            ))
                            }
                            <div className="display_start">
                                <Button type="submit" className="btn_about full-width">Submit</Button>
                            </div>
                        </Form>
                </Paper>
            </Container>
        </div>
    )
}