import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

export default function DCard({label, gross, value, icon, background}){
    return(
      <Paper className="paper_card" style={{height: '100%'}} variant="elevation" elevation={6}>
        <Box
        display="flex"
        justifyContent="space-between"
        paddingTop="8px"
        paddingLeft="16px"
        paddingRight="16px"
        color="rgb(52, 71, 103)"
        boxShadow="none"
        height= '100%'
        >
            <Box className="box_avatar_card" style={{background: background}}>
                {icon}
            </Box>
            <Box className="box_text_card">
                <span>{label}</span>
                <h4>{value.toLocaleString()}</h4>
                {gross &&
                  <p className="mb-0 pb-0 p_gross">{gross} (-10%)</p>
                }
            </Box>
        </Box>

        <Divider className="divider_card" />
      </Paper> 
    )
}