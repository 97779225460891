import '../assets/css/Home.css';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';

import comp from '../assets/img/comp.png';
import comp_mob from '../assets/img/comp-mob.png';
import AboutComponent from '../components/AboutComponent';
import MiningCalculator from '../components/MiningCalculator';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import PlansComponent from '../components/PlansComponent';
import StatsHome from '../components/StatsHome';

import miners from '../assets/img/miners.webp';
import minersO from '../assets/img/miners+.webp';
import DigitalMinersHome from '../components/DigitalMinersHome';

export default function Home(){
    const matches = useMediaQuery('(max-width:700px)');
    const navigate = useNavigate();

    return(
        <div className="home_page">
            <div className="home_header">
                    <img className="float_home" src={miners} alt="miners"/>
                    <img className="float_home1" src={minersO} alt="miners"/>
                
                <Container>
                    <h1 className="h1_home">Cloud Mining: Maximizing Returns. </h1>
                    <p className="p_home p_1">
                        HashCash Mining Dubai is dedicated to democratizing access to cloud mining, catering to the needs of both seasoned power users and newcomers alike.
                    </p>

                    <div className="home_btngroup">
                        <Button onClick={() => navigate('/register')}>Start Mining</Button>
                    </div>

                    <div className="p_home_1">
                    ENABLING PARTICIPATION AND BENEFITS IN CLOUD MINING FOR USERS OF ALL EXPERIENCE LEVELS
                        <img src={matches ? comp_mob : comp} alt='comp' />
                    </div>
                    
                </Container>
            </div>

            <div>
                <AboutComponent />
            </div>

            <div>
                <StatsHome />
            </div>

            <div className="home_minting pb-5">
                <Container className="container_mining1 about_component pb-5 mt-3" style={{background: 'transparent', border: 'none'}}>
                    <p>Mining Calculator</p>
                    <h1>Mine your first crypto</h1>
                </Container>
                <MiningCalculator />

                
                <Container className="container_mining1 mt-3" style={{background: 'transparent', border: 'none'}}>
                    <div>                    
                        <Grid className="grid_mining1" container spacing={3}>
                            <Grid item xs={12} sm={6} md={4}>
                               <div className="minting_check"><VerifiedOutlinedIcon/>No mining equipment required</div>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <div className="minting_check"><VerifiedOutlinedIcon/> No battery or CPU drainage</div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                <div className="minting_check"><VerifiedOutlinedIcon/> Fast and risk-free</div>
                            </Grid>
                        </Grid>
                    </div>
                </Container>
            </div>

            <div className="pt-5 mt-5">
                <Container className="container_mining1 about_component pb-5 mt-3 text-center" style={{background: 'transparent', border: 'none'}}>
                    <p>Our Best Plans</p>
                    <div className="display_center pb-5 mb-3">
                        <h1>Unleash Your Earning Power</h1>
                    </div>

                    <PlansComponent />
                </Container>
            </div>

            <div className="pt-5 mt-5">
                <Container className="container_mining1 about_component pb-5 mt-3 text-center" style={{background: 'transparent', border: 'none'}}>
                   <DigitalMinersHome />
                </Container>
            </div>
        </div>
    )
}