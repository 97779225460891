import * as React from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from '@mui/material/Button';
import { useSelector } from 'react-redux';
import { createBrowserHistory } from "history";

import logo from '../assets/img/logo_small.png';

function NavBar(props) {
  const {user} = useSelector(state => state.auth);
  const history = createBrowserHistory()

  const [isTop, changeTop] = React.useState(true);

  const [value, setValue] = React.useState('');
  const location = useLocation();
  const navigate = useNavigate();

  function changeTopValue() {
    if (window.scrollY > 250){
        changeTop(false);
    }else{
        changeTop(true);
    }
  }

  window.addEventListener('scroll', changeTopValue);

  React.useEffect(() => {
    const loc = location.pathname.toLowerCase().split('/')[1];
    if(loc === ''){
      setValue('/')
    }else{
      setValue('/' + loc)
    }
  }, [location])
  
  
  return (
    <Navbar fixed={history.location.pathname === '/' ? !isTop ? 'top' : '' : ''} sticky={history.location.pathname === '/' ? isTop ? 'top' : '' : 'top'} style={{boxShadow: 'none'}} variant="dark" collapseOnSelect expand="lg">
      <Container>
        <Navbar.Brand href="/">
            <div style={{cursor: 'pointer', display: 'flex', justifyContent: 'center'}} onClick={() => navigate('/')}>
              <img src={logo} alt="logo" style={{width: '4.5rem'}} />
            </div>
          </Navbar.Brand>
          <Navbar.Toggle className="toggle_home" aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav style={{gap: '1rem'}} activeKey={value} className="me-auto">
              <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link href="/about">About</Nav.Link>
              <Nav.Link href="/plans">Plans</Nav.Link>
              <Nav.Link href="/contact">Contact</Nav.Link>

              {!user && <Nav.Link href="/login">Login</Nav.Link>}
              
              <Button onClick={() => navigate('/register')} className="btn_about mt-0 ms-3">{user ? 'Dashboard' : 'Get Started'}</Button>
            </Nav>
          </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

NavBar.propTypes = {
  window: PropTypes.func,
};

export default NavBar;