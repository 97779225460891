import Button from '@mui/material/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import {useState, useEffect} from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import axiosInstance from '../redux/axios';

export default function AdminConfirm({open, setOpen, w, setSelectedW, UpgratePackage}){
    const [hash, setHash] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const matches = useMediaQuery('(max-width:600px)');

    useEffect(() => {
        setError('');
        setSuccess('');
    }, [hash])

    useEffect(() => {
        if(success !== ''){
            setError('');
        }
    }, [success])


    const confirm = async () => {
        if(hash !== ''){
            try{
                const data = {
                    hash: hash
                }
                
                const response = await axiosInstance.post('withdraw/'+w?._id+'/accept', data);
             
                if(response?.status === 201){
                    w.status = "completed";
                    w.hash = hash;
                    UpgratePackage(w);
                    setSuccess('Transaction for ' + w?.userId + ' with amount ' + w?.amount+ ' accepted.')
                }
            
            }catch(err) {
                if(err?.response?.data){
                    setError(err.response.data)
                } 
            }
        }else{
            setError('Fill in the form');
        }
    }

    
    return(
        <Modal
        data-bs-theme="dark"
        show={open}
        fullscreen={matches}
        onHide={() => {setOpen(false);setSelectedW(null)}}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                Accept transaction for {w?.userId} with amount {w?.amount}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form.Group className="mb-3" controlId="popUp.amount">
                <Form.Label>Transaction Hash proof</Form.Label>
                <InputGroup hasValidation>
                    <Form.Control type="string" value={hash} onChange={(e) => setHash(e.target.value)}  placeholder="" />
                </InputGroup>
            </Form.Group>

            {error && <p className="text_error">{error}</p>}
            {success && <p className="text_success">{success}</p>}
        </Modal.Body>
        <Modal.Footer>
            <div style={{display: 'flex', flexWrap: 'nowrap', gap: '1rem'}}>
                <Button style={{color: 'white', borderColor: 'white'}} variant="outlined" onClick={() => {setOpen(false);setSelectedW(null)}}>Close</Button>
                <Button className="confirm_btn" variant="contained" onClick={() => confirm()}>Confirm</Button>
            </div>
        </Modal.Footer>
        </Modal>
    )
}